import {Component, ElementRef, Input, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {Modal, ModalContainer} from "../../modules/app-modal/modal.module";

import {ApiService} from '../../services/api/api.service'
import {ILabelModel, IUserModel, TopicClass} from "@idetic/frontend-interfaces";
import {MessagingService} from "../../modules/messaging/messaging.service";
import {Subscription} from 'rxjs';

declare const jQuery:any

@Component({
  selector: 'block-topic-overlay',
  template: `
    <div class="modal fade-in modal-dark" (click)="closeModal()">
      <div class="modal-dialog">
        <div class="modal-content" (click)="stopPropagation($event)">
          <div class="modal-header topic-title">
            <h4 class="modal-title">
              <ng-container *ngIf="topic.type=='group'">
                <input *ngIf="editTitle" type="text" [(ngModel)]="inputTitle" (change)="onChangeTitle()"
                       class="form-control required topic-title-edit" (blur)="editTitle=false"/>
                <span *ngIf="!editTitle" (click)="onBeginEditTitle()"
                      [style.cursor]="'pointer'">{{topic.title}}</span>
              </ng-container>
              <span *ngIf="topic.type=='direct'">{{topic.getTitle()}}</span>
            </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()"><span
              aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-header topic-settings" *ngIf="topic.type == 'group'">
            <div class="topic-users">
              <span class="topic-setting-label">Teilnehmer </span>
              <ng-container *ngIf="topic">
            <span *ngFor="let curUser of topic.has_Users" class="badge badge-user clickable"
                  (click)="removeUser(curUser)">
              {{curUser.displayName}} <i class="glyphicon glyphicon-remove"></i>
            </span>
              </ng-container>
              <span class="btn btn-default" (click)="isAddingUser = true"
                    *ngIf="!isAddingUser  && getUnassignedUsers().length"><i
                class="glyphicon glyphicon-plus"></i></span>
              <ng-container *ngIf="isAddingUser">
            <span *ngFor="let curUser of getUnassignedUsers()" class="badge badge-info clickable"
                  (click)="addUser(curUser)">
              <i class="glyphicon glyphicon-plus"></i> {{curUser.displayName}}
            </span>
              </ng-container>
            </div>

            <!--<div class="topic-labels" *ngIf="topic.uuid">
              <span class="topic-setting-label">Labels </span>
              <span *ngFor="let curLabel of topic.has_Labels" class="badge badge-default" (click)="removeLabel(curLabel)" [style.background]="curLabel.color">
                {{curLabel.name}} <i class="glyphicon glyphicon-remove"></i>
              </span>
              <span class="btn btn-default" (click)="isAddingLabel = true" *ngIf="!isAddingLabel  && getUnassignedLabels().length"><i class="glyphicon glyphicon-plus"></i></span>
              <ng-container *ngIf="isAddingLabel">
                <span *ngFor="let curLabel of getUnassignedLabels()" class="badge" (click)="addLabel(curLabel)" [style.borderColor]="curLabel.color" [style.color]="curLabel.color">
                  <i class="glyphicon glyphicon-plus"></i> {{curLabel.name}}
                </span>
              </ng-container>
            </div>-->
          </div>
          <div class="modal-body topic">
            <ng-container *ngIf="user">
              <timeline [topic]="topic"></timeline>

              <div class="topic-readby clear text-success"
                   *ngIf="topic.isReadBy_Users && topic.isReadBy_Users.length">
                <i class="glyphicon glyphicon-ok"></i> Gelesen von
                <ng-container
                  *ngFor="let curUser of topic.isReadBy_Users; let isLast = last">{{curUser.displayName}}
                  <ng-container *ngIf="!isLast">,</ng-container>
                </ng-container>
              </div>

            </ng-container>

            <div class="row clear" style="padding-top:10px;">
              <div class="col-md-10">
                <input type="text" class="form-control" id="message" [(ngModel)]="message" (keydown)="keydown($event)"
                       placeholder="Nachricht schreiben...">
                <a class="topic-button-upload" (click)="selectFile()"><i class="far fa-paperclip"></i></a>
              </div>
              <div class="col-md-2">
                <button type="submit" [class.btn-primary]="canSubmit()" [class.btn-outline-primary]="!canSubmit()"
                        class="btn btn-block topic-button-send" (click)="addComment()"><i
                  class="fas fa-paper-plane"></i></button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <input #fileUpload type="file" (change)="addFile()" class="d-none"/>

  `
})
@Modal()
export class TopicOverlayComponent extends ModalContainer implements OnInit, OnDestroy {
  @Input() topic: TopicClass
  @ViewChild("fileUpload") fileUpload:ElementRef<HTMLInputElement>;

  // private localTopic: Topic
  public editTitle: boolean = false
  public inputTitle:string
  private message: string = ""
  private isAddingUser:boolean = false
  private isAddingLabel:boolean = false

  private user:IUserModel | undefined
  private users:IUserModel[] = []
  private labels:ILabelModel[] = []

  private timelineReloadSubscription:Subscription


  constructor(private api: ApiService, private messagingService: MessagingService) {
    super();
  }

  async ngOnInit() {
    this.user = this.api.getUser()

    // Neues Topic?
    if (this.topic.name == "new") {
      this.topic.name = TopicClass.getRandomGroupName();
      this.onBeginEditTitle()
    }

    // Topic laden (falls existiert)
    await this.topic.loadMessages()
    await this.topic.setRead()

    this.users = (await this.api.get("users")) || []
    this.labels = (await this.api.get("labels")) || []

    this.timelineReloadSubscription = await this.messagingService.timelineReload$.subscribe((topicName) => { if (this.topic.name == topicName) this.reloadMessages();})
  }

  async ngOnDestroy() {
    this.timelineReloadSubscription.unsubscribe()
  }

  async keydown (event:any) {
    if(event.keyCode == 13) {
      await this.addComment()
    }
  }

  private async reloadMessages() {
    await this.topic.loadMessages()
  }

  private getUnassignedUsers(): IUserModel[] {
    if (!this.topic.uuid) return this.users
    var userIndex = {}
    this.topic.has_Users.forEach(function(user:IUserModel) {
      userIndex[user.uuid] = user
    })
    var users:IUserModel[] = []
    this.users.forEach(function(user) {
      // if (user.uuid == ___this.user.uuid) return;
      if (userIndex[user.uuid]) return;
      users.push(user)
    })
    return users
  }

  private getUnassignedLabels(): ILabelModel[] {
    var ___this = this
    if (!this.topic.uuid) return this.labels
    var index = {}
    this.topic.has_Labels.forEach(function(label) {
      index[label.uuid] = label
    })
    const unassignedLabels = this.labels.filter((label) => { return !index[label.uuid]; })
    return unassignedLabels
  }

  private async addUser(user:IUserModel) {
    this.isAddingUser = false
    this.topic.addUser(user)
  }

  private async addLabel(label:ILabelModel) {
    this.isAddingLabel = false
    await this.api.put("topics/"+(await this.topic.getUuid())+"/has/labels/"+label.uuid)
    this.topic.loadMessages() // Async Update
  }

  private async removeUser(user:IUserModel) {
    await this.topic.removeUser(user.uuid)
  }

  private async removeLabel(label:ILabelModel) {
    await this.api.delete("topics/"+(await this.topic.getUuid())+"/has/labels/"+label.uuid)
    this.topic.loadMessages() // Async Update
  }

  private onBeginEditTitle() {
    this.editTitle = true
    this.inputTitle = this.topic.title
    window.setTimeout(function() { jQuery('.topic-title-edit').focus().select(); }, 1)
  }

  private async onChangeTitle() {
    this.editTitle = false
    const newTitle = this.inputTitle.trim()
    if (!newTitle) return; // Leerer Namen nicht möglich.

    this.topic.title = newTitle
    await this.api.put("topics/" + (await this.topic.getUuid()), {
      name: this.topic.name,
      title: newTitle
    })
  }

  protected canSubmit():boolean {
    return typeof this.user != "undefined" && !!this.message
  }

  async addComment() {
    if (!this.canSubmit()) return
    const message = this.message
    this.message = ""

    // Nachricht senden
    await this.topic.addComment(message)

    // Nachrichten neu laden
    await this.topic.loadMessages()

    return false
  }

  async addFile() {
    await this.topic.getUuid()
    const result = await this.api.uploadFile(this.fileUpload.nativeElement)

    await this.api.post("topics/"+this.topic.uuid+"/has/comments/", {
      user_uuid: this.user.uuid,
      has_Files:[result.uuid]
    })

    // Nachrichten neu laden
    await this.topic.loadMessages()
  }

  public selectFile() {
    jQuery(this.fileUpload.nativeElement).click()
  }
}
