declare var IDpath:any

export class MenuItemConfig {
    /*label: string;
    uri: string;*/

    constructor(public label:string, public uri:string, public icon:string | null = null) {
        if (icon && icon.match(/\@idetic\/ext\-[a-zA-Z0-9\-\.\/]+/))
            this.icon = IDpath.getStaticUri()+icon
    }
}
