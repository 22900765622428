import { Pipe, PipeTransform } from '@angular/core';
import {ApiService} from "../services/api/api.service";

@Pipe({ name: 'url',  pure: false })
export class UrlPipe implements PipeTransform {

  constructor(private api: ApiService) {}

  transform(value: any, args: any[] = []): any {
    return this.api.resolveUriPlaceholders(value)
  }
}
