import { NgModule, Component, Input, OnInit }      from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApiService} from "../../services/api/api.service";

@Component({
  selector: 'icon',
  template: `
    <ng-container *ngIf="src">
      <i *ngIf="!iconIsImage()" [class]="src+' icon '+(cssClass?cssClass:'')" [style.font-size]="height"></i>
      <img *ngIf="iconIsImage()" [src]="srcAbsolute" [class]="'icon '+(cssClass?cssClass:'')" [style.width]="width" [style.height]="height" />
    </ng-container>`
})
export class IconComponent implements OnInit  {
  @Input() src: string | null | undefined
  public srcAbsolute: string
  @Input() cssClass: string
  @Input() width: string
  @Input() height: string

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    if (!this.src) return; // Kein Bild?
    if (typeof this.src != "string") throw "IconComponent.src ist kein String ("+typeof this.src +"): "+JSON.stringify(this.src)
    this.srcAbsolute = this.apiService.resolveUriPlaceholders(this.src);
  }

  iconIsImage(): boolean {
    if (this.src.indexOf("/") !== -1) return true; // Relative Pfade
    // Absolute URIs
    if (this.src.match(/^([a-z0-9+.-]+):(?:\/\/(?:((?:[\w-._~!$&'()*+,;=:]|%[0-9A-F]{2})*)@)?((?:[\w-._~!$&'()*+,;=]|%[0-9A-F]{2})*)(?::(\d*))?(\/(?:[\w-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*)?|(\/?(?:[\w-._~!$&'()*+,;=:@]|%[0-9A-F]{2})+(?:[\w-._~!$&'()*+,;=:@\/]|%[0-9A-F]{2})*)?)(?:\?((?:[\w-._~!$&'()*+,;=:\/?@]|%[0-9A-F]{2})*))?(?:#((?:[\w-._~!$&'()*+,;=:\/?@]|%[0-9A-F]{2})*))?$/))
      return true;
    return false
  }
}


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IconComponent
  ],
  exports: [
    IconComponent
  ]

})
export class IconModule {}
