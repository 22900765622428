import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser'


import {NavigationService} from "./services/navigation.service";
import {ApiService} from "./services/api/api.service";
import {MessagingService} from './modules/messaging/messaging.service';

@Component({
  selector: 'app-root',
  template: `
<content>
  <div class="content-inner">
    <router-outlet></router-outlet>
  </div>
</content>
<loading  *ngIf="isNavigating"></loading>
<modal-placeholder></modal-placeholder>
`
})
export class AppComponent implements OnInit {
  name = 'Angular';
  isNavigating:boolean = true

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private api: ApiService,
    private messagingService: MessagingService,
    private title: Title,
    private cdRef:ChangeDetectorRef
    //private overlay: Overlay,
    //vcRef: ViewContainerRef,
    //public modal: Modal
  ) {}

  async ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        // console.log("Start navigation...")
        this.isNavigating = true
        // if (route.queryParams) {}
        this.navigationService.close()
      }
      if (val instanceof NavigationEnd || val instanceof  NavigationCancel) {
        // console.log("End navigation...")
        this.isNavigating = false
        this.cdRef.detectChanges();
      }
    });

    // Server-Config laden
    this.title.setTitle("DiraGo | "+this.api.publicConfig.name+" "+this.api.publicConfig.version)

    // Services hochfahren
    this.messagingService.init();

    this.api.watchForRedirect()

    if (this.api.publicConfig.pollTopics) {
      setInterval(() => {
        this.api.watchForRedirect()
      }, 3000)
    }

    // this.translate.setDefaultLang(this.api.publicConfig.lang);
    // overlay.defaultViewContainer = vcRef;
  }
}
