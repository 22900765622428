import {IdeticError} from "./IdeticError";
import {IPublicFileReference} from "./IPublicFileReference"

export class PublicFileReference implements IPublicFileReference {
    _publicFileReference: string
    _fontIcon: string

    constructor(file:string) {

        if (typeof file != "string") {
            throw new IdeticError("PublicFileReference erwartet einen String, erhalten: "+JSON.stringify(file))
        }
        if (this.isIcon(file))
            this._fontIcon = file
        else
            this._publicFileReference = file
    }

    isIcon(file:string): boolean {
        if (file == 'scan' || file.indexOf("glyphicon-") != -1 || file.indexOf("fa-") != -1)
            return true
        return false
    }
}
