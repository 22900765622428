import {Component, OnInit} from '@angular/core';
import {ApiService} from './services/api/api.service';

@Component({
  selector: 'config',
  template: `
    <loading *ngIf="exportLoading"></loading>
    <header mode="sysaction"></header>
    <h1>System</h1>
    <h2>Datensicherung</h2>
    <a class="btn btn-default" (click)="export()">Jetzt sichern</a>
    <div class="text-success mt-3" *ngIf="exportSuccessful"><i class="glyphicon glyphicon-ok"></i> Sicherung erstellt</div>
    <br /><br />
    
    <h2>Version</h2>
    {{revision}}
  `
})
export class ConfigComponent implements OnInit {
  public revision: string
  public exportLoading: boolean
  public exportSuccessful:boolean

  constructor(private apiService:ApiService) {

  }

  async ngOnInit() {
    let revisions:object = await this.apiService.get("config/revisions")
    this.revision = JSON.stringify(revisions).replace(/\"/g, "'")
  }

  async export() {
    if (this.exportLoading) return;
    this.exportLoading = true
    this.exportSuccessful = false
    await this.apiService.get("export-commit")
    this.exportLoading = false
    this.exportSuccessful = true
  }
}
