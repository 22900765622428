import {ILabelModel, ITopicModel, IUserModel} from ".";
import {IApiService} from "../IApiService";
import {DateFormatterClass} from "../DateFormatter.class";
import {IMessage} from "../IMessage";
const moment = require("moment");

export class TopicClass implements ITopicModel {
    public uuid?: string
    public name: string
    public title: string
    public type: 'group' | 'direct'
    public updated_at?: string
    public lastMessage?: string
    public lastMessageUser?: string
    public lastOnline?: string
    public intro?: string

    public has_Users?: IUserModel[] = []
    public isReadBy_Users: IUserModel[] = []
    public has_Labels: ILabelModel[] = []

    private _timeDisplayed: string;
    public messages: IMessage[] = []

    constructor(data: ITopicModel, private api:IApiService, private user:IUserModel) {
        this.setData(data)
        this.buildCache()
    }

    /*
    public static async loadByName(name:string, api:IApiService, user:IUserModel):Promise<TopicClass>{

    }*/

    public changed(otherTopic: TopicClass | undefined):boolean {
        // Metadaten geändert?
        if (this.metadataChanged(otherTopic)) return true;
        // Messages geändert?
        if (JSON.stringify(this.messages) != JSON.stringify(otherTopic.messages)) return true;
        return false;
    }

    public metadataChanged(otherTopic: TopicClass | undefined):boolean {
        // Anderes existiert nicht?
        if (!otherTopic) return true;
        // Metadaten geändert?
        if (JSON.stringify(this.getData()) != JSON.stringify(otherTopic.getData())) return true;
        return false;
    }


    public isNew(): boolean {
        if (!this.lastMessage) return false;
        if (!this.isMy()) return false;
        if (this.isReadBy(this.user)) return false;
        return true
    }
    public isMy(): boolean {
        return this.hasUser(this.user.uuid)
    }

    protected isReadBy(user: IUserModel):boolean {
        return this.isReadBy_Users.filter((isReadByUser) => isReadByUser.uuid == user.uuid).length != 0
    }
    public otherUsers(): IUserModel[] {
        return this.has_Users.filter((hasUser) => hasUser.uuid != this.user.uuid)
    }
    public hasUser(userUuid:string):boolean {
        return !!this.has_Users.filter((hasUser) => hasUser.uuid == userUuid).length
    }

    public async setRead():Promise<boolean> {
        if (this.isReadBy(this.user)) return false
        this.isReadBy_Users.push(this.user)
        if (!this.uuid) return false; // Noch kein Topic erstellt
        await this.api.put("topics/"+this.uuid+"/isreadby/users/"+this.user.uuid);
        return true
    }

    public setData(data:ITopicModel) {
        this.name = data.name
        this.title = data.title
        this.type = data.type

        this.uuid = data.uuid
        this.intro = data.intro;
        this.updated_at = data.updated_at
        this.lastMessage = data.lastMessage
        this.lastMessageUser = data.lastMessageUser
        this.lastOnline = data.lastOnline

        this.has_Users = data.has_Users || []
        this.isReadBy_Users = data.isReadBy_Users || []
        this.has_Labels = data.has_Labels || []
    }

    public getData():ITopicModel {
        return {
            name: this.name,
            title: this.title,
            type: this.type,

            uuid: this.uuid,
            intro: this.intro,
            updated_at: this.updated_at,
            lastMessage: this.lastMessage,
            lastMessageUser: this.lastMessageUser,
            lastOnline: this.lastOnline,

            has_Users: this.has_Users,
            isReadBy_Users: this.isReadBy_Users,
            has_Labels: this.has_Labels,
        }
    }

    public setMessages(messages:IMessage[] | undefined) {
        this.messages = messages || []
        this.buildCache()
    }

    public async exists():Promise<boolean> {
        const topics = await this.api.get("topics", {
            where: {
                name: this.name
            },
            attributes: ["uuid"]
        });
        return topics?false:(topics.length >= 1)
    }

    public async getUuid():Promise<string> {
        // Existiert das Topic bereits?
        if (this.uuid) return this.uuid;
        const topicMetadata = await this.loadMetadata();
        if (topicMetadata) return this.uuid;

        // Neu erstellen
        await this.api.post("topics", {
            name: this.name,
            title: this.title,
            type: this.type,
            intro: this.intro
        })
        // Daten laden
        await this.loadMetadata()
        return this.uuid
    }

    public async loadMessages():Promise<boolean>{
        if (!this.name) throw "TopicClass.name is undefined"

        // Messages laden
        const newMessages = (await this.api.get("messages/" +encodeURIComponent(this.name))) || []

        // Messages nicht geändert?
        if (JSON.stringify(this.messages) == JSON.stringify(newMessages)) return false;

        // Geändert
        this.messages = newMessages
        return true;
    }

    public async addComment(text:string) {
        // Sofort anzeigen
        const message:IMessage = {
            orderIncrement: null,
            topicName: this.name,
            message: text,
            userUuid: this.user.uuid,
            userDisplayName: this.user.displayName,
            userInitials: this.user.initials,
            createdAt: moment(),
            commentUuid: "temporary",
            has_Files: []
        }
        this.messages.push(message)

        // Kommentar in DB erstellen
        const comment = await this.api.post('topics/'+(await this.getUuid())+'/has/comments/', {
            message: text,
            user_uuid: this.user.uuid
        });

        // Mich selbst der Konversation hinzufügen und als gelesen markieren
        await this.addUser(this.user)
        await this.setRead()

        // Neu laden
        // await this.loadMessages()
    }

    public async addUser(user: IUserModel) {
        // Bereits Teil der Konversation?
        if (this.hasUser(user.uuid)) return;

        // Hinzufügenc
        this.has_Users.push(user)
        console.log("Füge Benutzer hinzu", user)
        await this.api.put("topics/"+(await this.getUuid())+"/has/users/"+user.uuid,{any:true} )
    }

    public async removeUser(userUuid: string) {
        // Gar nicht Teil der Konversation?
        if (!this.hasUser(userUuid)) return;

        // Entfernen
        this.has_Users = this.has_Users.filter((hasUser) => hasUser.uuid != userUuid)
        await this.api.delete("topics/"+(await this.getUuid())+"/has/users/"+userUuid)
    }

    public getTitle():string {
        if (this.type == 'group') return this.title
        if (this.type == 'direct') {
            const otherUsers = this.has_Users.filter((hasUser) => hasUser.uuid != this.user.uuid)
            if (otherUsers.length != 1) throw "Direct Messaging muss immer aus zwei Usern bestehen"!
            return otherUsers[0].displayName
        }
    }

    public isOnline():boolean {
        if (!this.lastOnline) return false;
        if (moment().diff(moment(this.lastOnline), "minutes", true) <= 1) {
            return true
        }
        return false;
    }

    protected async loadMetadata():Promise<ITopicModel | undefined> {
        const topics = await this.api.get("topics", {
            where: {
                name: this.name
            },
            include: [
                "has_Users",
                "isReadBy_Users",
                "has_Labels"
            ]
        })
        const topicData: ITopicModel | undefined = (topics && topics.length)?topics[0]:undefined
        if (topicData) this.setData(topicData)
        return topicData
    }

    protected buildCache() {
        this.calculateTimeLastComment()
    }

    protected calculateTimeLastComment() {
        this._timeDisplayed = DateFormatterClass.formatTimeRelative(this.updated_at)
    }

    public getTimeLastComment() {
        return this._timeDisplayed
    }

    public static getRandomGroupName():string {
        return "topic-"+(new Date().toISOString())+"-"+Math.floor(Math.random()*10000000)
    }
}
