import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class NavigationService {

  public navigationClose = new EventEmitter<void>();
  public navigationOpened = new EventEmitter<void>();

  constructor() {


  }

  close(): void {
    this.navigationClose.emit();
  }
  opened(): void {
    this.navigationOpened.emit();
  }
}
