import {PipesModule} from "../../pipes/pipes.module";
import {TopicModule} from "../../blocks/topic/topic.module";
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MessagesComponent} from "./messages.component";
import {HeaderModule} from "../header/header.module";

const routes: Routes = [
  {path: '', component: MessagesComponent},
];

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    TopicModule,
    HeaderModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    MessagesComponent
  ]
})
export class MessagingModule {
}
