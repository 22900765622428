import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';

import {AppComponent} from './app.component';
import {LoadingModule} from "./modules/loading/loading.module";
import {IconModule} from "./modules/icon/icon.module";
import {ModalModule} from "./modules/app-modal/modal.module";
import {PipesModule} from "./pipes/pipes.module";
import {SlickModule} from "./modules/ngx-slick/ngx-slick";
import {HeaderModule} from "./modules/header/header.module";
import {ConfigComponent} from "./config.component";
import {NavigationService} from "./services/navigation.service";
import {AppRoutingModule} from './modules/routing/app-routing.module';
import {DragulaModule} from 'ng2-dragula';
import {MessagingModule} from "./modules/messaging/messaging.module";
import {ApiModule} from "./services/api/api.module";

@NgModule({
  imports:      [
  	BrowserModule,
    HttpClientModule,
    LoadingModule,
    IconModule,
    ModalModule,
    PipesModule,
    SlickModule.forRoot(),
    HeaderModule,
    AppRoutingModule,
    DragulaModule.forRoot(),
    MessagingModule,
    ApiModule,
  ],
  declarations: [
  	AppComponent,
    ConfigComponent,
    //SafePipe
  ],
  providers: [
    NavigationService,
    Title,
  ],
  bootstrap:    [ AppComponent ]
})
export class AppModule { }
