import {APP_INITIALIZER, NgModule} from '@angular/core';
import {ApiService} from './api.service'
import {CookieService} from 'angular2-cookie/services/cookies.service';
import {HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from "../../modules/routing/app-routing.module";

export function init_app(apiService:ApiService) {
  return () => apiService.init()
}

@NgModule({
  imports: [
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    ApiService,
    CookieService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [ApiService], multi: true },
  ]
})
export class ApiModule { }
