import {Component, Input, OnInit} from '@angular/core';
import {SysactionBlock} from "../sysaction-block/sysaction-block.interface";
import {ApiService} from "../../services/api/api.service";
import {IMessage, ITimelineConfig, ITopicModel, TopicClass} from "@idetic/frontend-interfaces";

@Component({
  selector: 'block-timeline',
  template: `    
    <timeline *ngIf="topic" [topic]="topic" (scrolled)="onScrolledDown()"></timeline>
    <div class="clear"></div>
    <hr class="logs-divider" />
    `
})

export class BlockTimelineComponent implements SysactionBlock, OnInit {
  @Input() config: ITimelineConfig
  @Input() parent: SysactionBlock

  public topic: TopicClass

  private messages:IMessage[]

  private loadPromise: Promise<any>

  constructor(private api: ApiService) {}

  async ngOnInit() {
    // Weitere Daten zum Topic vorhanden?
    let topicData:ITopicModel
    const topicDataResult = await this.api.get("topics", { where: { name: this.config.uuid }})
    if (topicDataResult && topicDataResult.length)
      topicData = topicDataResult[0]
    else
      topicData = {name: this.config.uuid.toString(), title: "", type: "group"}

    const topic = new TopicClass(topicData, this.api, await this.api.getUser())
    this.messages = []
    await this.loadMessages()

    topic.setMessages(this.messages)
    await topic.setRead()

    this.topic = topic
  }

  protected async onScrolledDown() {
    // Nachladen
    console.log("onScrolledDown")
    await this.loadMessages()
    this.topic.setMessages(this.messages)
  }

  private async loadMessages() {
    // Lädt noch?
    if (this.loadPromise) await this.loadPromise;
    this.loadPromise = this.api.get((this.config.uri?this.config.uri+"/":"messages/")+this.config.uuid+"?start="+this.messages.length)
    const newMessages = (await this.loadPromise) || []
    this.messages = this.messages.concat(newMessages)
  }
}
