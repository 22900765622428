
interface ErrorOptions {
    // type: string
    detail?: string | any
    code?: number
    original?:Error
    // noStack?: boolean
}

export class IdeticError implements ErrorOptions {

    public detail: string | any
    public code: number = 400
    public stack: string
    public isError = true
    public original:Error
    // public  = false

    constructor(public message:string, options:ErrorOptions={}) {
        for (let i in options) {
            this[i] = options[i]
        }
        //if (!this.noStack)
        this.stack = Error().stack
        /* if (this.noStack) {
             //this.message2 = message
             this.stack = ""
         }*/
    }

    toString() {
        //if (this.noStack) return this.message
        return this.message+" in "+this.stack
    }
}
