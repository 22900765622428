import {Component, OnInit} from "@angular/core";
import {MessagingService} from "./messaging.service";
import {TopicClass} from "@idetic/frontend-interfaces";
import {TopicOverlayComponent} from "../../blocks/topic/topic-overlay.component";
import {TopicModule} from "../../blocks/topic/topic.module";
import {ModalService} from "../app-modal/modal.module";
import {ApiService} from "../../services/api/api.service";

@Component({
  selector: 'messages',
  template: `
    <header mode="sysaction"></header>
  <h1><i class="fas fa-comments"></i> Nachrichten
    <a class="btn btn-default " (click)="add()"><i class="fas fa-plus"></i> Neue Gruppe</a>
  </h1>
  <ul *ngIf="messagingService.topics" class="messages-container list-group">
    <li *ngFor="let topic of messagingService.topics" (click)="onClick(topic)" class="message list-group-item list-group-item-action" [class.new]="topic.isNew()" [class.bg-primary-light]="topic.isNew()">
      <div class="d-flex flex-row">
        <span class="message-title">{{topic.getTitle()}} <span *ngIf="topic.isOnline()" class="online text-success"><i class="fas fa-circle"></i> online</span></span>
        <span class="message-time">{{topic.getTimeLastComment()}}</span>
      </div>
      <div class="d-flex flex-row">
        <span class="message-last-message"><span *ngIf="topic.type == 'group' && topic.lastMessage">{{topic.lastMessageUser}}: </span><span [innerHTML]="topic.lastMessage"></span>&nbsp;</span>
        <span class="badge badge-primary badge-pill" *ngIf="topic.isNew()">1</span>
      </div>
    </li>
  </ul>
    
  `
})
export class MessagesComponent implements OnInit {

  constructor(private messagingService: MessagingService, private modalService: ModalService, private apiService: ApiService) {}

  async ngOnInit() {
    await this.messagingService.loadTopics()
  }

  onClick(topic:TopicClass) {
    this.modalService.create<TopicOverlayComponent>(TopicModule, TopicOverlayComponent, {
      topic: topic
    });
  }

  add() {
    const newTopic = new TopicClass({name:"new", type:"group", title: "Neue Gruppe"}, this.apiService, this.apiService.getUser())
    this.modalService.create<TopicOverlayComponent>(TopicModule, TopicOverlayComponent, {
      topic: newTopic
    });
  }

}
