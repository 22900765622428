import {Injectable, EventEmitter, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {CookieService} from 'angular2-cookie/services/cookies.service';
import {ActivatedRoute, Router} from '@angular/router'
import {
  DeniedUri,
  IApiService,
  IdeticError,
  IFile,
  IIdeticConfigDashboard,
  IUserModel
} from "@idetic/frontend-interfaces"
import {timeout} from "../../modules/labels/labels-admin.component";

const moment = require('moment')

@Injectable()
export class ApiService implements IApiService {

  private user:IUserModel | undefined

  public menuConfig:any = null
  public deniedUris:DeniedUri[]
  public publicConfig:IIdeticConfigDashboard

  private isSessionSetByQueryParam: boolean = false

  public onLoggedIn$ = new EventEmitter<void>();
  public onLoggedOut$ = new EventEmitter<void>();

  constructor(private http: HttpClient,
              private cookie: CookieService,
              private injector: Injector // Temporärer Fix
  ) {}

  async init() {
    // Api-Verbindung zum Server herstellen
    await this.loadPublicConfig()

    // Einloggen?
    if (this.getSession()) await this.loadUser()
  }

  private async loadPublicConfig() {
    if (this.publicConfig) return this.publicConfig
    // if (window.location.port == 4200)
    const port = (window.location.port == "4200")?"62080":window.location.port;
    const uri = window.location.protocol+"//"+window.location.hostname+(port?":"+port:"")+"/config"
    //console.log("Lade Public Config von "+uri)
    this.publicConfig = await this.get(uri)
    return this.publicConfig
  }

  async get(request:string, params:any = {}, options:any={}): Promise<any> {
    return this.getResponseOrThrowError(this.http.get(this.buildUri(request, params), { observe: 'response' }).toPromise(), options)
  }

  async post(request:string, body:any = {}, options:any = {}): Promise<any> {
    return this.getResponseOrThrowError(this.http.post(this.buildUri(request, {}), body, { observe: 'response' }).toPromise(), options)
  }

  async put(request:string, body:any = {}, options:any = {}): Promise<any> {
    console.log("PUT: "+request);
    return this.getResponseOrThrowError(this.http.put(this.buildUri(request, {}), body, { observe: 'response' }).toPromise(), options)
  }

  async delete(request:string, options:any = {}): Promise<any> {
    return this.getResponseOrThrowError(this.http.delete(this.buildUri(request, {}), { observe: 'response' }).toPromise(), options)
  }


  async uploadFile(fileUpload:HTMLInputElement): Promise<IFile> {

    const fileList: FileList = fileUpload.files;
    if(!fileList.length) { throw new IdeticError("Keine Datei ausgewählt")}

    const file: File = fileList[0];
    const formData:FormData = new FormData();
    formData.append('uploadFile', file, file.name);

    const headers = new Headers();
    /** No need to include Content-Type in Angular 4 */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.getResponseOrThrowError(this.http.post(this.buildUri("uploads/", {}, 81), formData, { observe: 'response' }).toPromise(), {headers: headers})
  }

  private async getResponseOrThrowError(promise:Promise<any>, options:any={}): Promise<any> {
    let response:HttpResponse<any> | HttpErrorResponse
    try {
      response = await promise
    } catch (e) {
      response = e
    }

    // Fehler?
    if (response instanceof HttpErrorResponse) {
      // 403 = Access Denied? -> Neu einloggen
      if (response.status == 403 && !options.noRedirectIf403) {
        this.logout()
        return {};
      }
      throw new IdeticError(response.message || "Unbekannter Fehler", { code: response.status })
    }

    // Keine Antwort
    if (!(response instanceof HttpResponse)) {
      console.error("response", response)
      throw new IdeticError("Unbekannter Fehler: Keine HttpResponse von Server erhalten")
    }

    // Antwort erhalten
    return response.body
  }

  private buildUri(requestPath: string, params: any={}, port:number=10): string {
    if (!params) params = {};
    var value: string = "";

    // Session an jeden Request anhängen
    params.session = this.getSession();

    // Relative URI?
    if (requestPath.substr(0, ("http://").length) != "http://" &&
      requestPath.substr(0, ("https://").length) != "https://")
      requestPath = this.getPortUri(port) + requestPath;

    for (var i in params) {
      if (requestPath.indexOf("?") === -1) requestPath += "?"; else requestPath += "&";
      if (typeof params[i] == "object")
        value = JSON.stringify(params[i])
      else
        value = params[i]
      requestPath += i + "=" + value;
    }
    return requestPath;
  }

  private parseUri(relativeUriWithQuery:string):{path:string, queryParams:{[key:string]:string | number}} {
    const path = relativeUriWithQuery.indexOf('?') === -1?relativeUriWithQuery:relativeUriWithQuery.substr(0, relativeUriWithQuery.indexOf('?'))

    function getQueryParams(uri: string) {
      if (uri.indexOf('?') === -1) return {};

      var query = uri.substr(uri.indexOf('?') + 1)
      var vars = query.split('&');
      var queryParams = {};
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=', 2);
        queryParams[pair[0]] = pair[1];
      }
      return queryParams;
    }

    return {
      path: path,
      queryParams: getQueryParams(relativeUriWithQuery)
    }
  }

  public getPortUri(port:number):string {
    if (port < 100) port = this.publicConfig.basePort + port
    return this.publicConfig.protocol+"://"+this.publicConfig.domain+":"+port+"/"
  }


  private async setSession(uuid:string): Promise<void> {
    if (!uuid) return;

    this.cookie.put("session", uuid, {
      expires: moment().add(1, "year").toDate()
    })
    this.user = undefined
    await this.loadUser()
    this.onLoggedIn$.emit()
    console.log("User logged in")
  }

  getSession(): string {
    return this.cookie.get("session") || ""
  }

  public getUser(): IUserModel | undefined {
    return this.user
  }

  public async login(name:string, password:string) {
    let session = await this.post("user/login", {
      name: name,
      password: password
    }, { noAlert: true, noRedirectIf403: true })

    await this.setSession(session.uuid)
  }

  public async loginByTag (tagUuid:string) {
    let session = await this.post("user/login-by-tag/"+tagUuid, {
      deviceUuid: "13e990defa5e17b3" // TODO
    }, { noAlert: true, noRedirectIf403: true })
    await this.setSession(session.uuid)
  }

  public logout(redirect:boolean = true): void {
    // TODO: DELETE Session
    this.user = undefined
    this.cookie.remove("session")
    this.onLoggedOut$.emit()

    if (redirect)
      this.injector.get(Router).navigate(['login'])
  }

  private async loadUser(): Promise<IUserModel | undefined> {
    console.log("Lade User...")
    if (!this.getSession()) {
      console.log("Session-Cookie nicht gesetzt")
      this.logout()
      return
    }
    let user:IUserModel
    try {
      user = (await this.get("sessions/"+this.getSession(), { include: ["User"]}, {noAlert:true})).User
      if (!user) throw "Session-Abfrage führt zu leerem Ergebnis"
    } catch (e) {
        console.log(e)
        console.log("Session konnte nicht geladen werden -> Login")
        this.logout()
        return
    }

    // Rechte laden
    let deniedUris
    try {
      deniedUris = (await this.get("user-denied-uris"))  || []
    } catch (e) {
      console.log(e)
      console.log("Rechte konnte nicht geladen werden -> Login")
      this.logout()
      return
    }

    // Daten gleichzeitig setzen
    this.user = user
    this.deniedUris = deniedUris
    console.log("User geladen.")

    // Nun Menü laden
    this.menuConfig = await this.loadMenu()

    return this.user
  }

  private async loadMenu() {
    const menuConfig = await this.get("menu-config/")
    for (let groupName in menuConfig) {
      const group = menuConfig[groupName]
      for (let itemName in group.items) {
        const item = group.items[itemName]
        const parsedUri = this.parseUri(item.uri)
        if (!this.checkUriAccess(parsedUri.path, parsedUri.queryParams)) {
          delete group.items[itemName]
        }
      }
      if (Object.keys(group.items).length == 0)
        delete menuConfig[groupName]
    }

    return menuConfig
  }

  /* TEMP! Rudimentäre Sysaction-Implementierung */
  async watchForRedirect() {
    // console.log(this.cookie.get("watchRedirect"))
    if (!this.cookie.get("watchRedirect") || typeof this.cookie.get("watchRedirect") != "string") return ;
    let workstation = await this.get("workstations/"+this.cookie.get("watchRedirect"))
    // console.log(workstation)
    if (!workstation.redirect) return;
    this.put("workstations/"+workstation.uuid, {redirect:""}) // Async
    window.location.href = "#/"+workstation.redirect
  }


  public checkUriAccess(path:string, queryParams:{[name:string]:string | number }):boolean {
    console.log("Prüfe Zugriffsrechte: "+path+" ("+JSON.stringify(queryParams)+")")
    if (!this.deniedUris) throw "Zugriffsrechte nicht geladen"

    // Suchen...
    if (path.charAt(0) == "/") path = path.substr(1)
    if (path.indexOf("?") != -1) path = path.substr(0, path.indexOf("?"))

    let matchedDeniedUris = this.deniedUris.filter((deniedUri) => {
      if (deniedUri.pathname != path) return false
      for (let i in deniedUri.params) {
        if (queryParams[i] != deniedUri.params[i]) return false
      }
      return true
    })

    if (matchedDeniedUris.length) {
      console.log("Access Denied")
      return false
    }
    return true
  }

  /**
   * v3
   */
  public resolveUriPlaceholders(uri: string): string {
    // Wird ein Platzhalter verwendet?
    const placeholderMatch = uri.match(/^([\@a-z]+)[:\/]/)
    if (!placeholderMatch) return uri;

    // Ist der Platzhalter konfiguriert?
    const placeholders = (<any>this.publicConfig).uriPlaceholders;
    if (!placeholders || !placeholders[placeholderMatch[1]]) return uri;
    // throw "Platzhalter '" + placeholderMatch[1] + "' konnte nicht ersetzt werden (uri: "+uri+")";

    // Gefunden...
    const placeholderConfig = placeholders[placeholderMatch[1]]
    uri = this.getPortUri(placeholderConfig.proxyPort) + (placeholderConfig.proxyPath || "") + uri.substr(placeholderMatch[1].length + 1)

    return uri
  }

  public redirectTo(uri:string) {
    if (!uri) throw "Invalid redirect path: "+uri
    console.log("Redirect to: "+uri)

    // Spezielle URL mit Platzhalter?
    uri = this.resolveUriPlaceholders(uri)

    // Direkt weiterleiten, wenn absolute URL
    if (uri.indexOf("https://") != -1 || uri.indexOf("http://") != -1) {
      window.open(uri)
      return;
    }

    // (relative) Angular-URL
    const parsedUri = this.parseUri(uri)
    this.injector.get(Router).navigate([parsedUri.path], {queryParams: parsedUri.queryParams})
  }
}
