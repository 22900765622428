import {Component, Input} from '@angular/core';
import {AgRendererComponent} from 'ag-grid-angular/main';
import {TopicOverlayComponent} from './topic-overlay.component'
import {SysactionBlock} from '../sysaction-block/sysaction-block.interface'
import {ApiService} from "../../services/api/api.service";

import {TopicModule} from './topic.module'
import {ModalService} from "../../modules/app-modal/modal.module";
import {TopicClass} from "@idetic/frontend-interfaces";
import {ITopicConfig} from "@idetic/frontend-interfaces";

@Component({
  selector: 'block-topic',
  template: `    
    <a (click)="onClick()" *ngIf="topic" [class]="getClass()">
      <i [class]="config.iconBefore" *ngIf="config.iconBefore"></i> <span *ngIf="config.label" class="topic-button-label">{{config.label}}</span>
      <img src="assets/icons/comment.svg" *ngIf="!config.iconBefore"/>
      <span *ngIf="topic.uuid" class="glyphicon glyphicon-asterisk"></span>
    </a>
  `
})

export class TopicComponent implements SysactionBlock, AgRendererComponent {
  @Input() config: ITopicConfig
  @Input() parent: SysactionBlock

  protected topic: TopicClass

  constructor(private api: ApiService,
              private modalService: ModalService,
  ) {}

  async ngOnInit() {
    this.topic = new TopicClass(this.config.topicData, this.api, await this.api.getUser())
  }

  async onClick () {
    this.modalService.create<TopicOverlayComponent>(TopicModule, TopicOverlayComponent, {
      topic: this.topic
    });
  }

  getClass() {
    let defaultClass = "topic-btn btn btn-default mode-btn "+(this.config.cssClass || "")+" ";
    if (!this.config.topicData.uuid) return defaultClass
    if (!this.topic.isMy()) {
      return defaultClass+"other"
    }
    if (this.topic.isNew())
      return defaultClass+"me new"
    return defaultClass+"me"
  }

  agInit(params: any): void {
    this.refresh(params)
  }

  refresh(params: any): boolean {
    this.config = params.data[params.colDef.field];
    return true

  }

  public static agComparator(val1:ITopicConfig, val2:ITopicConfig):number {
    return (val1.topicData.uuid?1:0) - (val2.topicData.uuid?1:0);
  }

}
