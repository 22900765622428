import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuesPipe }  from './values.pipe';
import { SafeStylePipe }  from './safe-style.pipe';
import {UrlPipe} from "./url.pipe";
import {SafeHtmlPipe} from "./safe-html.pipe";


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ValuesPipe,
    SafeStylePipe,
    SafeHtmlPipe,
    UrlPipe
  ],
  exports: [
    ValuesPipe,
    SafeStylePipe,
    SafeHtmlPipe,
    UrlPipe
  ]
})
export class PipesModule {}
