import { NgModule, forwardRef }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TopicComponent }  from './topic.component';
import { TopicOverlayComponent} from './topic-overlay.component';
import {TimelineComponent} from "./timeline.component";
import {BlockTimelineComponent} from "./block-timeline.component";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InfiniteScrollModule
  ],
  declarations: [
    forwardRef(() => TopicComponent ),
    TimelineComponent,
    TopicOverlayComponent,
    BlockTimelineComponent
  ],
  exports: [
    forwardRef(() => TopicComponent ),
    BlockTimelineComponent
  ],
  entryComponents: [
    TopicOverlayComponent
  ]
})
export class TopicModule {}
