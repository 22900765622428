export class ConsoleLogClass {

    constructor(private name: string, private verbose: boolean) {}

    public log(a:string,b?:any) {
        if (!this.verbose) return;
        a = this.name+": "+a
        typeof b != "undefined"?console.log(a,b):console.log(a)
    }

    public error(a:string,b?:any) {
        a = this.name+": "+a
        typeof b != "undefined"?console.error(a,b):console.error(a)
    }
}
