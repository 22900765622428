import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Router} from '@angular/router'
import {NavigationService} from "../../services/navigation.service";
import {MessagingService} from "../messaging/messaging.service";

@Component({
  selector: 'header',
  template: `
    <div id="topnav-print"><img src="assets/diaratio-dark.svg" class="logo" alt="Diaratio Technologie GmbH"></div>
    
    <nav *ngIf="mode == 'sysaction'" id="topnav" class="navbar navbar-expand navbar-dark fixed-top mr-auto" [class.mode-dev]="api.publicConfig?.mode=='dev' || api.publicConfig?.mode=='staging'" >
      <div class="navbar-brand logo-area clickable" (click)="home()">
       <img src="assets/diaratio.svg" class="logo" alt="Diaratio DiraGO">
      </div>
      <ul class="navbar-nav">
        <ng-container *ngFor="let config of api.menuConfig | values">
          <li *ngIf="config.items" class="nav-item dropdown" menuGroup [config]="config"></li>
          <li *ngIf="config.uri" class="nav-item" menuItem></li>
        </ng-container>
      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="topics-container nav-item"  *ngIf="getUser()">
          <a class="nav-link clickable" (click)="topics()">
            <i class="far fa-comments"></i>
            <span class="badge badge-primary badge-pill" *ngIf="messagingService.getNewTopicCount() > 0">{{messagingService.getNewTopicCount()}}</span>
          </a>
        </li>
        <li class="nav-item round account_actions dropdown clickable" [class.active]="showUserDropdown" *ngIf="getUser()" (click)="showUserDropdown=!showUserDropdown">
          <a id="user_toggle" class="initials">{{getUser().initials}}</a>
          <ul class="dropdown-menu userinfo" *ngIf="showUserDropdown">
            <!--<li><a href="#/"><i class="far fa-user"></i><span>Profil</span></a></li>
            <li><a href="#/"><i class="far fa-cog"></i><span>Einstellungen</span></a></li>
            <li class="divider"></li>-->
            <li><a class="dropdown-item" (click)="logout()"><i class="far fa-sign-out"></i><span>Logout</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <nav *ngIf="mode == 'login'" id="topnav" class="navbar navbar-expand navbar-light fixed-top mr-auto">
      <div class="navbar-brand logo-area">
        <img src="assets/diaratio-dark.svg" class="logo" alt="Diaratio DiraGO">
      </div>
    </nav>
    
    <nav *ngIf="mode == 'onboarding'" id="topnav" class="navbar navbar-expand navbar-light fixed-top mr-auto">
      <div class="navbar-brand logo-area">
        <img src="assets/diaratio-dark.svg" class="logo" alt="Diaratio DiraGO">
      </div>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item round account_actions dropdown clickable" [class.active]="showUserDropdown" *ngIf="getUser()" (click)="showUserDropdown=!showUserDropdown">
          <a id="user_toggle" class="initials">{{getUser().initials}}</a>
          <ul class="dropdown-menu userinfo" *ngIf="showUserDropdown">
            <!--<li><a href="#/"><i class="far fa-user"></i><span>Profil</span></a></li>
            <li><a href="#/"><i class="far fa-cog"></i><span>Einstellungen</span></a></li>
            <li class="divider"></li>-->
            <li><a class="dropdown-item" (click)="logout()"><i class="far fa-sign-out"></i><span>Logout</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <nav *ngIf="mode == 'diaratio'" id="topnav" class="navbar navbar-expand navbar-light fixed-top mr-auto mt-1">
      <div class="navbar-brand logo-area">
        <img src="assets/diaratio-dark.svg" class="logo" alt="Diaratio">
      </div>
    </nav>

    <div id="dropdown-overlay" class="modal" *ngIf="dropdownOpened || showUserDropdown" (click)="closeDropdown()"></div>`
})
export class HeaderComponent implements OnInit {
  @Input() public mode: "login" | "sysactions" | "onboarding" | "diaratio"
  private dropdownOpened:boolean = false;
  private showUserDropdown: boolean = false;

  constructor(
	    private api: ApiService,
      private router: Router,
      private navigationService: NavigationService,
      private messagingService: MessagingService
	) {

  }

	async ngOnInit() {
	  let __this = this
	  // this.apiService.get("menu-config")
    //this.user = this.apiService.getUser()
    //console.log(this.user)

    this.navigationService
      .navigationClose
      .subscribe(() => {
        __this.dropdownOpened = false
        __this.showUserDropdown = false
      });
    this.navigationService
      .navigationOpened
      .subscribe(() => {
        __this.dropdownOpened = true
        __this.showUserDropdown = false
      });
	}

  getUser() {
    return this.api.getUser()
  }
	getNewMessages(): number {
	    return 1;
	}
	logout() {
	  this.navigationService.close()
	  this.api.logout()
  }
  home() {
	  this.router.navigate(['sysactions/home'])
  }
  topics() {
	  // console.log("topics")
    this.router.navigate(['messages'])
  }
  closeDropdown() {
	  this.navigationService.close()
  }
}
