import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../../services/api/api.service";
import {DateFormatterClass, IUserModel, TopicClass} from "@idetic/frontend-interfaces";
import {MessagingService} from "../../modules/messaging/messaging.service";

@Component({
  selector: 'timeline',
  template: `
    <div class="search-results"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScrollDown()"
         [fromRoot]="false"
    >
      <div *ngFor="let message of topic.messages; let i = index">
        <div *ngIf="isNewDate(i)" class="text-center comment-date text-secondary">{{formatDateRelative(message.createdAt)}}</div>
        <ng-container *ngIf="message.commentUuid">
        
          <div class="comment right" *ngIf="message.userUuid == user.uuid">
            <span class="comment-message" *ngIf="message.message" [innerHtml]="message.message"></span>
            <span class="comment-file" *ngFor="let file of message.has_Files"><img [src]="resolveFileUri(file.uri)" [title]="file.name"/></span>
            <span class="comment-time" [class.comment-time-file]="message.has_Files.length">{{formatTime(message.createdAt)}}</span>
          </div>

          <div class="comment left" *ngIf="message.userUuid != user.uuid">
            <span class="user-display-name" *ngIf="message.userUuid">{{message.userDisplayName}}</span>
            <span class="comment-message" *ngIf="message.message"
                  [innerHtml]="message.message"></span>
            <span class="comment-file" *ngFor="let file of message.has_Files"><img
              [src]="resolveFileUri(file.uri)" [title]="file.name"/></span>
            <span class="comment-time"
                  [class.comment-time-file]="message.has_Files.length">{{formatTime(message.createdAt)}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="message.logUuid">
          <div class="log">
            <span class="user-display-name" *ngIf="message.userUuid">{{message.userDisplayName}}</span>
            <span class="comment-message" [innerHtml]="message.message"></span>
            <span class="comment-time">{{formatTime(message.createdAt)}}</span>
          </div>
        </ng-container>
      </div>
    </div>
  `
})
export class TimelineComponent implements OnInit {
  @Input() topic: TopicClass
  @Output() scrolled = new EventEmitter()
  user:IUserModel | undefined


  constructor(private api: ApiService, private messagingService: MessagingService) {}

  async ngOnInit() {
    this.user = this.api.getUser()
  }

  private isNewDate(messageIndex:number):boolean {
    if (messageIndex <= 0) return true
    if (DateFormatterClass.formatDate(this.topic.messages[messageIndex-1].createdAt) !=
      DateFormatterClass.formatDate(this.topic.messages[messageIndex].createdAt)) {
      return true
    }
    return false
  }

  private formatTime(time:string) {
    return DateFormatterClass.formatTime(time)
  }


  private formatDateRelative(time:string) {
    return DateFormatterClass.formatDateRelative(time)
  }


  private resolveFileUri(uri:string) {
    return this.api.resolveUriPlaceholders(uri);
  }

  protected onScrollDown() {
    this.scrolled.emit()
  }
}
