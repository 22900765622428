import {NgModule} from "@angular/core";
import {HeaderComponent} from "./header.component";
import {PipesModule} from "../../pipes/pipes.module";
import {MenuItemComponent} from "./menu-item.component";
import {MenuGroupComponent} from "./menu-group.component";
import {IconModule} from "../icon/icon.module";
import {CommonModule} from "@angular/common";

@NgModule({
  imports:      [
  	CommonModule,
    PipesModule,
    IconModule
  ],
  declarations: [
  	HeaderComponent,
    MenuItemComponent,
    MenuGroupComponent,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
