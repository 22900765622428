import { Component, Input } from '@angular/core';
import { MenuItemConfig, MenuGroupConfig } from '@idetic/frontend-interfaces'
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: '[menuItem]',
  template: `
    <a class="dropdown-item" href="javascript:void(0)" (click)="onClick()"><span class="icon-wrapper"><icon *ngIf="config.icon" [src]="config.icon"></icon></span>{{config.label}}</a>
  
  `
})
export class MenuItemComponent {

  @Input() private config: MenuItemConfig
  @Input() private menuGroupConfig: MenuGroupConfig

  private active:boolean = false

  constructor(private navigationService: NavigationService) {

  }

  onClick() {
    window.location.href="#/"+this.config.uri
    // if (this.menuGroupConfig) this.menuGroupConfig.setActive()
  }

}
