import * as moment from 'moment'
moment.locale("de")

export class DateFormatterClass {

  private static simulateTime: string | undefined = undefined; //"2018-03-28 10:31:00"

  public static formatTime(timeString:string):string {
    return moment(timeString).format("HH:mm")
  }

  public static formatDate(timeString:string):string {
    return moment(timeString).format("DD.MM.YYYY")
  }

  public static formatDateRelative(timeString:string):string {
    let time = moment(timeString)
    let now = moment(this.simulateTime)
    if (now.format("DD.MM.YYYY") == time.format("DD.MM.YYYY"))
      return "Heute"
    if (now.subtract(1, "day").format("DD.MM.YYYY") == time.format("DD.MM.YYYY"))
      return "Gestern"

    return moment(timeString).format("DD.MM.YYYY")
  }

  public static formatTimeRelative(timeString:string | undefined):string {
    if (!timeString) return ""
    const time = moment(timeString);
    const diff = moment(this.simulateTime).diff(time, "days")
    if (diff == 0) return time.format("HH:mm")
    else if (diff <= 6) {
      return time.format("dddd")
    }
    else return time.format("DD.MM.YYYY")
  }

  public static formatTimeOnline(timeString:string):string {
    if (!timeString) return ""
    const time = moment(timeString);
    const diff = moment(this.simulateTime).diff(time, "days")
    if (diff == 0) {
      // Heute
      const diffMinutes = moment(this.simulateTime).diff(time, "minutes")
      if (diffMinutes <= 1) return "online";
      if (diffMinutes <= 15) return "vor "+diffMinutes+" Minuten";
      return time.format("HH:mm");
    } else {
      // Länger her
      return this.formatDateRelative(timeString)+", "+time.format("HH:mm")
    }
  }
}
