import {Injectable, EventEmitter} from '@angular/core';
import {ConsoleLogClass, ITopicModel, TopicClass} from '@idetic/frontend-interfaces';
import {ApiService} from "../../services/api/api.service";
import Timeout = NodeJS.Timeout;

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  public topics: TopicClass[]

  private timeoutRef: Timeout;
  private log = new ConsoleLogClass('MessagingService', true);

  public timelineReload$ = new EventEmitter<string>();

  constructor(private apiService: ApiService) {}

  public async init() {
    this.apiService.onLoggedIn$.subscribe(() => {this.registerPollTimeout(2000)})
    this.apiService.onLoggedOut$.subscribe(() => {clearTimeout(this.timeoutRef)})
    if (this.apiService.getUser()) this.registerPollTimeout(2000)

    this.log.log("init")
  }

  private registerPollTimeout(delay: number) {
    if (this.apiService.publicConfig.pollTopics && this.apiService.getUser()) {
      if (this.timeoutRef) clearTimeout(this.timeoutRef);
      this.timeoutRef = setTimeout(() => {
        this.pollTopics();
      }, delay);
    }
  }

  // vgl. Dricorder
  public async loadTopics(): Promise<TopicClass[]> {
    const topicData: ITopicModel[] = (await this.apiService.get('topics-my') || []);
    const newTopics = topicData.map((topic) => new TopicClass(topic, this.apiService, this.apiService.getUser()));
    // Noch nichts hinterlegt? -> Komplett übertragen
    if (!this.topics  || newTopics.length != this.topics.length) {
      this.topics = newTopics;
      return this.topics;
    }

    // Änderungen?
    for (let i = 0; i < this.topics.length; i++) {
      const curTopic = this.topics[i]
      const newTopic = newTopics[i]

      // Neue Sortierung?
      if (newTopic.name != curTopic.name) {
        // Derzeit: alles ersetzen
        this.topics = newTopics
        return this.topics
      }

      // Abweichungen?
      if (curTopic.metadataChanged(newTopic)) {
        curTopic.setData(newTopic)
        if (curTopic.metadataChanged(newTopic)) throw "Update der Metadaten hat nicht geklappt";
        this.timelineReload$.emit(curTopic.name);

        // this.events.publish('timeline:reload:' + curTopic.name)
      }
    }
    return this.topics
  }

  public getNewTopicCount():number {
    if (!this.topics) return 0;
    return this.topics.filter((topic) => topic.isNew()).length
  }

  private async pollTopics() {
    await this.loadTopics()

    // Erneut aufrufen
    this.registerPollTimeout(2000);
  }
}
