import { Component, Input } from '@angular/core';
import { MenuGroupConfig } from '@idetic/frontend-interfaces'
import {NavigationService} from "../../services/navigation.service";

@Component({
  selector: '[menuGroup]',
  template: `
      <a href="javascript:void(0)" (click)="open()" class="nav-link dropdown-toggle">{{config.label}} <span class="caret"></span></a>
      <div *ngIf="displayMenu" class="dropdown-menu"><div menuItem *ngFor="let item of config.items | values" [config]="item"></div></div>
  `
})
export class MenuGroupComponent {

  @Input() private config: MenuGroupConfig

  private displayMenu: boolean = false

  constructor(private navigationService: NavigationService) {
    let __this = this
    this.navigationService
      .navigationClose
      .subscribe(() => {
        __this.displayMenu = false
      });
  }

  open() {
    if (this.displayMenu) {
      this.displayMenu = false
    } else {
      this.navigationService.close()
      this.displayMenu = true
      this.navigationService.opened()
    }
  }

}
