import {Component, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home.component";
import {ConfigComponent} from '../../config.component';
import {LoadingModule} from "../loading/loading.module";


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', loadChildren: '../login/login.module#LoginModule' },
  { path: 'download', loadChildren: '../download/download.module#DownloadModule' },
  { path: 'access-denied', loadChildren: '../access-denied/access-denied.module#AccessDeniedModule' },
  { path: 'sysactions', loadChildren: '../views/views.module#ViewsModule'},
  { path: 'workflow-editor', loadChildren: '../workflow-editor/workflow-editor.module#WorkflowEditorModule'},
  { path: 'wizard', loadChildren: '../wizard/wizard.module#WizardModule'},
  { path: 'config', component: ConfigComponent},
  { path: 'onboarding', loadChildren: '../onboarding/onboarding.module#OnboardingModule' },
  { path: 'labels', loadChildren: '../labels/labels.module#LabelsModule'},
  { path: 'messages', loadChildren: '../messaging/messaging.module#MessagingModule'}
];


@NgModule({
  imports: [LoadingModule,
    RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  declarations: [HomeComponent]
})
export class AppRoutingModule { }
