import { Pipe, PipeTransform } from '@angular/core';

import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Pipe({name: 'safeStyle'})
export class SafeStylePipe {
  constructor(private sanitizer:DomSanitizer){}

  transform(style:string) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
